<template>
  <div>
    <div>
      <el-row justify="center">

        <el-col class="flex md6 xs12 row-bg">
          <div>
            <el-date-picker
              format="dd/MM/yyyy"
              v-model="data"
              type="daterange"
              range-separator="|"
              start-placeholder="Data inicial"
              end-placeholder="Data final"
              value-format="dd/MM/yyyy"
              @change="escolherData"
            >
            </el-date-picker>
          </div>
        </el-col>
        <el-col class="flex md6 xs12">
          <div>
            <el-select
              v-model="tipoDeData"
              @change="escolherData"
            >
              <el-option
                v-for="item in opcoes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <br>
      <data-tables
        style="width: 100%"
        :data="acoes"
        :table-props="tableProps"
        layout="table"
        v-loading="loading"
      >
        <el-table-column
          :prop="`${campo}_nome`"
          :label="titulo"
          sortable="custom"
        />
        <el-table-column
          :prop="`${campo}_total`"
          label="Numero de Ações"
        />
        <el-table-column
          :prop="`${campo}_noprazo`"
          label="Ações no Prazo"
        >
          <template slot-scope="acao">
            {{preparaPorcentagem(acao.row[`${campo}_total`], acao.row[`${campo}_noprazo`])}}
          </template>
        </el-table-column>
        <el-table-column
          prop="acoes_atrazadas"
          label="Ações Atrasadas"
        >
          <template slot-scope="acao">
            {{preparaPorcentagem(acao.row[`${campo}_total`], acao.row[`${campo}_atrasada`])}}
          </template>
        </el-table-column>
        <el-table-column
          prop="acoes_concluidas"
          label="Ações Concluídas"
        >
          <template slot-scope="acao">
            {{preparaPorcentagem(acao.row[`${campo}_total`], acao.row[`${campo}_concluida`])}}
          </template>
        </el-table-column>
      </data-tables>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    loading: {
      type: Boolean,
    },
    acoes: Array,
    campo: {
      type: String,
      default: 'responsavel',
    },
    titulo: {
      type: String,
      default: 'Usuário',
    },
  },
  data() {
    return {
      tipoDeData: 'prazo',
      opcoes: [
        { label: 'Prazo', value: 'prazo' },
        { label: 'Data de Cadastro', value: 'data_cadastro' },
      ],
      data: null,
      tableProps: {
        border: true,
      },
    };
  },
  methods: {
    escolherData() {
      const dataFormatada = [];
      dataFormatada.push(this.data[0].replace(/\//g, '%2F'));
      dataFormatada.push(this.data[1].replace(/\//g, '%2F'));
      this.$emit('data', dataFormatada, this.tipoDeData);
    },
    preparaPorcentagem(total, parte) {
      if (parte === 0) return `${parte} (${0}%)`;
      return `${parte} (${((parte * 100) / total).toFixed(2)}%)`;
    },
  },
};
</script>

<style>
</style>
